import React from 'react';

import * as Yup from 'yup';
import moment from 'moment';

import PolicyDetailsPage from 'pages/addOrEditPtoPolicy/component/PolicyDetailsPage';
import HolidayCalender from 'pages/addOrEditPtoPolicy/component/HolidayCalender';
import AccuralPolicyPage from 'pages/addOrEditPtoPolicy/component/AccuralPolicyPage';
import BalanceSettingPage from 'pages/addOrEditPtoPolicy/component/BalanceSettingPage';
import EmployeeSettingPage from 'pages/addOrEditPtoPolicy/component/EmployeeSettingPage';

import {
  BALANCE_SETTING_VALIDATION_MESSAGES,
  POLICY_DETAILS_VALIDATION_MESSAGES,
  ACCURAL_VALIDATION_MESSAGES,
  HOLIDAY_VALIDATION_MESSAGES
} from 'pto/strings';
import { ACCURAL_TYPE_VALUES } from 'pto/constants';

export const ACCURAL_VALIDATION_SCHEMA = Yup.object().shape({
  total_accural_hours: Yup.number()
    .typeError(ACCURAL_VALIDATION_MESSAGES.TOTAL_ACCURAL_HOURS_TYPE_ERROR)
    .required(ACCURAL_VALIDATION_MESSAGES.TOTAL_ACCURAL_HOURS_REQUIRED)
    .min(0, ACCURAL_VALIDATION_MESSAGES.TOTAL_ACCURAL_HOURS_MIN)
    .integer(ACCURAL_VALIDATION_MESSAGES.TOTAL_ACCURAL_HOURS_INT_ERROR),
  based_on_hours_worked_per: Yup.number()
    .typeError(ACCURAL_VALIDATION_MESSAGES.BASED_ON_HOURS_WORKED_PER_TYPE_ERROR)
    .min(0, ACCURAL_VALIDATION_MESSAGES.BASED_ON_HOURS_WORKED_PER_MIN)
    .integer(ACCURAL_VALIDATION_MESSAGES.BASED_ON_HOURS_WORKED_PER_INT_ERROR),
});

export const POLICY_DETAILS_VALIDATION_SCHEMA = Yup.object().shape({
  policy_name: Yup.string()
    .trim()
    .required(POLICY_DETAILS_VALIDATION_MESSAGES.POLICY_NAME_REQUIRED),
  policy_type: Yup.string().required(POLICY_DETAILS_VALIDATION_MESSAGES.POLICY_TYPE_REQUIRED),
});

export const BALANCE_SETTING_VALIDATION_SCHEMA = Yup.object().shape({
  waiting_period_days: Yup.number()
    .typeError(BALANCE_SETTING_VALIDATION_MESSAGES.WAITING_PERIOD_DAYS_TYPE_ERROR)
    .moreThan(-1, BALANCE_SETTING_VALIDATION_MESSAGES.WAITING_PERIOD_DAYS_MORE_THAN)
    .lessThan(365, BALANCE_SETTING_VALIDATION_MESSAGES.WAITING_PERIOD_DAYS_LESS_THAN)
    .integer(BALANCE_SETTING_VALIDATION_MESSAGES.WAITING_PERIOD_DAYS_INTEGER),
  carry_over_limit_value: Yup.number()
    .typeError(BALANCE_SETTING_VALIDATION_MESSAGES.CARRY_OVER_LIMIT_VALUE_TYPE_ERROR)
    .moreThan(-1, BALANCE_SETTING_VALIDATION_MESSAGES.CARRY_OVER_LIMIT_VALUE_MORE_THAN)
    .lessThan(99999, BALANCE_SETTING_VALIDATION_MESSAGES.CARRY_OVER_LIMIT_VALUE_LESS_THAN)
    .integer(BALANCE_SETTING_VALIDATION_MESSAGES.CARRY_OVER_LIMIT_VALUE_INTEGER),
  max_balance_limit: Yup.number()
    .typeError(BALANCE_SETTING_VALIDATION_MESSAGES.MAX_BALANCE_LIMIT_TYPE_ERROR)
    .moreThan(-1, BALANCE_SETTING_VALIDATION_MESSAGES.MAX_BALANCE_LIMIT_MORE_THAN)
    .lessThan(99999, BALANCE_SETTING_VALIDATION_MESSAGES.MAX_BALANCE_LIMIT_LESS_THAN)
    .nullable(true)
    .integer(BALANCE_SETTING_VALIDATION_MESSAGES.MAX_BALANCE_LIMIT_INTEGER),
});

export const HOLIDAY_VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(HOLIDAY_VALIDATION_MESSAGES.NAME_REQUIRED),
  number_of_hours: Yup.number()
    .typeError(HOLIDAY_VALIDATION_MESSAGES.NUMBER_OF_HOURS_TYPE_ERROR)
    .moreThan(-1, HOLIDAY_VALIDATION_MESSAGES.NUMBER_OF_HOURS_MORE_THAN)
    .lessThan(25, HOLIDAY_VALIDATION_MESSAGES.NUMBER_OF_HOURS_LESS_THAN)
    .integer(HOLIDAY_VALIDATION_MESSAGES.NUMBER_OF_HOURS_INTEGER)
    .required(HOLIDAY_VALIDATION_MESSAGES.NUMBER_OF_HOURS_REQUIRED),
  date: Yup.date().required(HOLIDAY_VALIDATION_MESSAGES.DATE_REQUIRED),
});

export const getAccuralInitialValues = (data, dateFormatDisplay) => {
  return {
    accural_type: data.accural_type ? data.accural_type : ACCURAL_TYPE_VALUES.YEARLY,
    total_accural_hours:
      data.accural_config?.total_accural_hours === undefined ? 0 : data.accural_config?.total_accural_hours,
    use_it_or_lose_it: data ? data.use_or_lose_it : false,
    accure_day_wise:
      data.accural_config?.accure_day_wise === undefined ? 5 : parseInt(data.accural_config.accure_day_wise),
    every_pay_period_per:
      data.accural_config?.every_pay_period_per === undefined ? 'year' : data.accural_config?.every_pay_period_per,
    based_on_hours_worked_per:
      data.accural_config?.based_on_hours_worked_per === undefined ? 0 : data.accural_config?.based_on_hours_worked_per,
    accural_date: data?.accural_date ? moment(data?.accural_date).format(dateFormatDisplay) : '',
    accural_date_check: false,
    accural_on_hire_date: data.accural_on_hire_date ? 'true' : 'false',
    allow_entries_outside_employee_current_accural_year: data
      ? data.allow_entries_outside_employee_current_accural_year
      : false,
    id: data.id,
  };
};

export const getBalanceSettingInitialValues = data => {
  return {
    waiting_period: data.accural_config?.waiting_period === true ? 'true' : 'false',
    waiting_period_days: data.accural_config?.waiting_period_days ? data.accural_config.waiting_period_days : 0,
    carry_over_limit: data.accural_config
      ? data.accural_config.carry_over_limit === true
        ? "Yes there's a limit"
        : 'No, all hours can be carried over'
      : null,
    carry_over_limit_value: data.accural_config?.carry_over_limit_value
      ? data.accural_config.carry_over_limit_value
      : 0,
    max_balance_limit: data.max_balance_limit ? data.max_balance_limit : null,
    id: data.id,
  };
};

export const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

export const getDefaultStep = (query) => {
  if (query.tabId === 'policyDetails' || query.tabId === 'policy-details') return 0;
  if (query.tabId === 'holiday') return 1;
  if (query.tabId === 'accural') return 1;
  if (query.tabId === 'balance') return 2;
  if (query.tabId === 'employee') return query?.policy_type === 'Paid Holidays' ? 2 : 3;
};

export const getPolicyDetailsInitialValues = policyData => {
  return {
    policy_name: policyData?.policy_name || '',
    policy_type: policyData?.policy_type || '',
    code: policyData?.code || '',
    id: policyData?.id,
  };
};

export const HOLIDAY_INITIAL_VALUES = { date: '', name: '', number_of_hours: '' };

export const getSteps = (
  eventEmitter,
  submitting,
  setSubmitting,
  disabled,
  setActiveStep,
  dateFormat,
  dateFormatDisplay,
  policyId,
  tags,
  policyData,
  setLoader,
  query,
  policyType
) => {
  return [
    {
      title: 'Policy Details',
      content: (
        <PolicyDetailsPage
          policyData={policyData}
          eventEmitter={eventEmitter}
          submitting={submitting}
          setSubmitting={setSubmitting}
          disabled={disabled}
          setActiveStep={setActiveStep}
        />
      ),
    },
    ...(policyType === 'PAID_HOLIDAYS'
      ? [
          {
            title: 'Holiday Calender',
            content: (
              <HolidayCalender
                data={policyData}
                dateFormat={dateFormat}
                setSubmitting={setSubmitting}
                eventEmitter={eventEmitter}
                disabled={disabled}
                dateFormatDisplay={dateFormatDisplay}
                setActiveStep={setActiveStep}
                policyId={policyId}
                submitting={submitting}
              />
            ),
          },
        ]
      : [
          {
            title: 'Accural Setting',
            content: (
              <AccuralPolicyPage
                data={policyData}
                eventEmitter={eventEmitter}
                setActiveStep={setActiveStep}
                dateFormat={dateFormat}
                dateFormatDisplay={dateFormatDisplay}
                submitting={submitting}
                setSubmitting={setSubmitting}
                disabled={disabled}
              />
            ),
          },
          {
            title: 'Balance Setting',
            content: (
              <BalanceSettingPage
                data={policyData}
                eventEmitter={eventEmitter}
                setActiveStep={setActiveStep}
                submitting={submitting}
                setSubmitting={setSubmitting}
                disabled={disabled}
              />
            ),
          },
        ]),
    {
      title: 'Employee Selection',
      content: (
        <EmployeeSettingPage
          data={policyData}
          eventEmitter={eventEmitter}
          setActiveStep={setActiveStep}
          policyId={policyId}
          tags={tags}
          submitting={submitting}
          setSubmitting={setSubmitting}
          disabled={disabled}
          setLoader={setLoader}
          query={query}
        />
      ),
    },
  ];
};

export const POLICY_TYPE_OPTIONS = [
  { label: 'Paid Time Off', value: 'PAID_TIME_OFF' },
  { label: 'Unpaid Time Off', value: 'UNPAID_TIME' },
  { label: 'Paid Holidays', value: 'PAID_HOLIDAYS' },
];

export const WAITING_PERIOD_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const CARRY_OVER_LIMIT_OPTIONS = ["Yes there's a limit", 'No, all hours can be carried over'];
